import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import {
    defaultPostOverview,
    Epoch,
    PostFilterValues,
    PostListingPage,
    PostListItem,
    PostOverview,
    ImageScale,
    POST_TYPES,
    PostListingPageId
} from "../../models";
import { SortStyle } from "utils/managementUtils";
import moment from "moment";
import Loading from "modules/common/components/loading";

import PostFilters from "../post-filters/postFilters";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import WarningIcon from '@mui/icons-material/Warning';
import ClearIcon from "@mui/icons-material/Clear";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TimelineIcon from "@mui/icons-material/Timeline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

import confirm from "utils/notyPopups";
import { postsApi } from "api/instances";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import Paging from "modules/common/components/paging";
import ChipLabels from "modules/common/components/chips/chipLabels";
import Cookies from "js-cookie";
import MoreOptions from "./moreOptions";
import { TenantSettingsTagGroup } from "modules/settings";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import Preview, { PreviewType } from "modules/common/components/authoring/dialogs/preview";
import PostContentView from "../post-view/postContentView";
import { openPostInPortal } from "utils/redirectUtils";
import { getHighestRole } from "utils/userRoleUtils";
import { UserRoleStrings } from "modules/authorization/models";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/styles";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import { ConfirmRepublishCompliancePostDialog } from "modules/common/components/dialogs/confirmRepublishCompliancePostDialog";
import { contentBandsSlice } from "modules/contentBands/reducer";
import { isPinnedDeleted } from "modules/common/hooks/data/useContentBands";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";
import EditPublishedContentDialog from "../../../common/components/authoring/dialogs/editPublishedContentDialog";
import { ContentType } from "modules/common/models";
import HoverText from "modules/documents/components/action-buttons/hoverText";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.2)',
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        "&::before": {
            border: '1px solid rgba(153, 153, 153, 0.5)'
        }
    },
}));

interface HeaderCell {
    id: string;
    label: string;
    ascValue?: SortStyle;
    descValue?: SortStyle;
    clickable?: boolean;
}

class PostList extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            hasFiltersApplied: false,
            isPagedLoad: false,
            selectedItems: [],
            showPostPreview: false,
            postToPreview: { ...defaultPostOverview },
            isLoadingPreview: false,
            successMessage: "",
            showEditPublishedPostDialog: false,
            currDraftId: "",
            showConfirmComplianceEditPost: false,
            showConfirmUnpublishCompliancePost: false,
            showConfirmPublishEditedCompliancePost: false,
        };
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public render() {
        const { page } = this.props;
        const { postToPreview } = this.state;
        const isPostPublishDateValid = postToPreview.datePublished && moment(postToPreview.datePublished).isBefore(moment());
        const isContributor = getHighestRole(this.props.currentUser) === UserRoleStrings.CONTRIBUTOR;

        // show 'open in portal' if post is already a published post
        const showOpenInPortalPreviewAction = postToPreview.status !== "draft" && isPostPublishDateValid;

        // show 'open published' version of draft if post is not a draft
        const showOpenPublishedVersion = postToPreview.status === "draft" && isPostPublishDateValid;

        const getPreviewOpenLabel = (): string | undefined => {
            let openLabel: string | undefined;

            if (showOpenInPortalPreviewAction)
                openLabel = "OPEN POST IN PORTAL";
            else if (showOpenPublishedVersion)
                openLabel = "VIEW PUBLISHED VERSION";

            return openLabel;
        };

        const getPreviewOpenAction = (): (() => void) | undefined => {
            let openAction: (() => void) | undefined;

            if (showOpenInPortalPreviewAction)
                openAction = () => openPostInPortal(this.props.tenant, this.state.postToPreview.id);
            else if (
                showOpenPublishedVersion &&
                this.state.postToPreview.previouslyPublishedPostId
            )
                openAction = () => openPostInPortal(this.props.tenant, this.state.postToPreview.previouslyPublishedPostId);

            return openAction;
        };

        const getPreviewPublishLabel = (): string | undefined => {
            let publishLabel: string | undefined;

            if (isContributor && this.props.page.id === 'drafts')
                publishLabel = 'Submit';
            else if (!isContributor && this.props.page.id === 'submissions')
                publishLabel = "Approve & Publish";

            return publishLabel;
        }

        const getPreviewPublishAction = (): (() => void) | undefined => {
            return this.props.page.id === 'submissions' || this.props.page.id === 'drafts' ?
                async () => {
                    let date = this.state.postToPreview.datePublished
                        ? new Date(this.state.postToPreview.datePublished)
                        : new Date();

                    try {
                        isContributor ?
                            await this.props.submitPost(this.state.postToPreview.draftId)
                            :
                            await this.props.publishPost(this.state.postToPreview.draftId, date.toISOString());
                    }
                    catch (err) { }
                    finally {
                        this.onHidePostPreview();
                    }
                }
                :
                undefined;
        };

        if (!this.props.show)
            return <></>;

        if (!this.state.isPagedLoad && page.isFetching)
            return <div style={{ paddingTop: "60px" }}><Loading /></div>;

        return (
            <React.Fragment>
                {this.getToolbar(isContributor)}
                <TabContent>
                    {this.getFilter()}
                    {this.getList(isContributor)}
                    <SnackbarWrapper
                        open={!!this.state.successMessage}
                        message={this.state.successMessage}
                        severity="success"
                        autoHideDuration={8000}
                        onClose={() => this.setState({successMessage: ""})}
                    />
                    {this.state.postToPreview &&
                        <Preview
                            editAction={async () => await this.onEditPost(isContributor, this.state.postToPreview.draftId, this.state.postToPreview.postType)}
                            editLabel={"EDIT POST"}
                            openAction={getPreviewOpenAction()}
                            openLabel={getPreviewOpenLabel()}
                            onClose={this.onHidePostPreview}
                            open={this.state.showPostPreview}
                            disablePublish={this.state.postToPreview.status === 'draft'}
                            previews={[{ type: PreviewType.Desktop, component: this.getDesktopPreview(), label: "DESKTOP" }]}
                            publishLabel={getPreviewPublishLabel()}
                            publishAction={getPreviewPublishAction()}
                            isLoading={this.props.publishing || this.props.submitting}
                        />}
                    <LoadingOverlay absolute={true} show={this.state.isLoadingPreview} />
                </TabContent>
                <EditPublishedContentDialog
                    content={ContentType.Post}
                    onEdit={this.onEditPostConfirm}
                    open={this.state.showEditPublishedPostDialog}
                    onClose={() => this.setState({ showEditPublishedPostDialog: false, currDraftId: '' })}
                />  
                <ConfirmDialog
                    title="Edit Compliance Post"
                    open={this.state.showConfirmComplianceEditPost}
                    confirmLabel="EDIT"
                    denyLabel="CANCEL"
                    onConfirm={this.onEditPostConfirm}
                    onDeny={() => this.setState({ showConfirmComplianceEditPost: false, currDraftId: "" })}
                    onClose={() => this.setState({ showConfirmComplianceEditPost: false, currDraftId: "" })}
                >
                    Editing a compliance post and publishing its changes will reset its Compliance Report, clearing the list of users who have complied to it.
                    <br /><br />
                    Would you like to proceed?
                </ConfirmDialog>
                <ConfirmDialog
                    title="Unpublish Compliance Post"
                    open={this.state.showConfirmUnpublishCompliancePost}
                    confirmLabel="UNPUBLISH"
                    denyLabel="CANCEL"
                    onConfirm={this.onUnpublishCompliancePostConfirm}
                    onDeny={() => this.setState({ currDraftId: "", showConfirmUnpublishCompliancePost: false })}
                    onClose={() => this.setState({ currDraftId: "", showConfirmUnpublishCompliancePost: false })}
                >
                    Unpublishing a compliance post and republishing any changes will reset its Compliance Report, clearing the list of users who have complied to it.
                    <br /><br />
                    Would you like to proceed?
                </ConfirmDialog>
                <ConfirmRepublishCompliancePostDialog
                    open={this.state.showConfirmPublishEditedCompliancePost}
                    onConfirm={() => {
                        this.setState({ currDraftId: "", showConfirmPublishEditedCompliancePost: false });
                        this.onPublishPostConfirm(this.state.currDraftId)
                    }}
                    onClose={() => this.setState({ currDraftId: "", showConfirmPublishEditedCompliancePost: false })}
                    onDeny={() => this.setState({ currDraftId: "", showConfirmPublishEditedCompliancePost: false })}
                />
            </React.Fragment>);
    }

    private getDesktopPreview = (): JSX.Element => (
        <div className="post-preview">
            {this.state.postToPreview &&
                <PostContentView
                    tinyMceCustomCssEnabled={this.props.tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                    reactions={this.props.tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                    reactionsEnabledOnTenant={this.props.tenantSettings.reactionsEnabled}
                    commentsEnabledOnTenant={this.props.tenantSettings.commentsEnabled}
                    post={this.state.postToPreview}
                    lcidMappings={this.props.lcidMappings}
                    defaultLcid={this.props.defaultLang}
                />}
        </div>
    );

    private formatEpochTime = (time?: Epoch): string => {
        if (time) {
            return moment(new Date(time.date)).format("MMM D, YYYY h:mmA");
        }
        return "-";
    }

    private formatEpochTimeFromNow = (time?: Epoch): string => {
        if (time) {
            return moment(new Date(time.date)).fromNow();
        }
        return "-";
    }

    private getFilter = (): JSX.Element => {
        return (
            <PostFilters
                filters={this.props.filters}
                pageId={this.props.page.id}
                onChangeFilters={this.onChangeFilters}
                onClearFilters={this.onClearFilters}
                availableTopics={this.props.availableTopics}
                onlyNewer={this.props.page.id === PostListingPageId.SCHEDULED}
                onlyOlder={this.props.page.id === PostListingPageId.PUBLISHED}
            />
        );
    }

    //Default to descending, if currently descending make it ascending.
    private onClickHeader = (descValue: SortStyle | undefined, ascValue: SortStyle | undefined) => {
        const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
        let updatedFilters = { ...this.props.filters };

        if (descValue === undefined || ascValue === undefined) {
            return;
        }

        if (this.props.filters.sortType === descValue) {
            updatedFilters = { ...updatedFilters, sortType: ascValue };
        } else {
            updatedFilters = { ...updatedFilters, sortType: descValue };
        }

        this.props.onUpdateFilters(
            updatedFilters,
            () => { this.onChangePage(0, Number(rowsPerPage) ?? 10, updatedFilters); }
        );
    }

    private getHeader = (): HeaderCell[] => {
        const { page } = this.props;

        let header: HeaderCell[] = [
            {
                id: "title",
                label: "Post title",
                ascValue: SortStyle.titleAsc,
                descValue: SortStyle.titleDesc,
                clickable: true
            }
        ];

        header.push({ id: "options", label: "" })

        if (page.id === PostListingPageId.ALL)
            header.push({
                id: "state",
                label: "State",
                ascValue: SortStyle.stateAsc,
                descValue: SortStyle.stateDesc,
                clickable: true
            });

        if (page.id !== PostListingPageId.DRAFTS && page.id !== PostListingPageId.SUBMISSIONS)
            header.push({
                id: "publishedTime",
                label: this.getPublishedTimeTabLabel(),
                ascValue: SortStyle.publishAsc,
                descValue: SortStyle.publishDesc,
                clickable: true
            });

        if (page.id === PostListingPageId.SUBMISSIONS)
            header.push({
                id: "lastSubmittedOn",
                label: "Submitted On",
                ascValue: SortStyle.submittedAsc,
                descValue: SortStyle.submittedDesc,
                clickable: true
            })

        header.push({
            id: "updatedTime",
            label: "Last modified",
            ascValue: SortStyle.modifiedAsc,
            descValue: SortStyle.modifiedDesc,
            clickable: true
        });

        if (page.id !== PostListingPageId.SUBMISSIONS) {
            header.push({
                id: "expiryTime",
                label: "Expiry date",
                ascValue: SortStyle.expiryAsc,
                descValue: SortStyle.expiryDesc,
                clickable: true
            });

            header.push({
                id: "postType",
                label: "Type",
                ascValue: SortStyle.typeAsc,
                descValue: SortStyle.typeDesc,
                clickable: true
            });
        }

        header.push({
            id: "author",
            label: "Published by",
            ascValue: SortStyle.authorAsc,
            descValue: SortStyle.authorDesc,
            clickable: true
        });

        header.push({
            id: "tags",
            label: "Topics",
        });

        return header;
    }

    private getList = (isContributor: boolean): JSX.Element => {
        const { page, filters } = this.props;
        const { selectedItems } = this.state;

        const getStatusColorToUse = (post: PostListItem): string => {
            let color = "yellow";
            if (post.status.toLowerCase() === "published")
                color = "green";
            else if (post.status.toLowerCase() === "draft" && post.isSubmission)
                color = "red";
            else if (post.status.toLowerCase() === "draft")
                color = "grey";
            else
                color = "yellow";

            return color;
        }

        const rowCount = page.posts.length || 10;

        if (page.isFetching)
            return <div style={{ paddingTop: "60px" }}><Loading /></div>;

        if (!page.posts.length)
            return (
                <ManagementEmptyResults searchType={"Posts"} hasFilters={this.state.hasFiltersApplied} />
            );

        return (
            <>
                {
                    this.state.hasFiltersApplied &&
                    <Typography variant="h2" className="filter-results">Results</Typography>
                }
                <TableContainer>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                                        checked={rowCount > 0 && selectedItems.length === rowCount}
                                        onChange={this.onSelectAllPosts}
                                        inputProps={{ "aria-label": "select all posts" }}
                                    />
                                </TableCell>
                                {
                                    this.getHeader()
                                        .map((headerCell) => (
                                            <TableCell
                                                key={headerCell.id}
                                                align={headerCell.id === "title" ? "left" : "center"}
                                                padding={headerCell.id === "title" ? "none" : "normal"}
                                                onClick={() => this.onClickHeader(headerCell.descValue, headerCell.ascValue)}
                                            >
                                                <div style={{
                                                    paddingBottom: filters.sortType === headerCell.ascValue || filters.sortType === headerCell.descValue ?
                                                        "8px" : "0px", cursor: headerCell.clickable ? "pointer" : ""
                                                }}
                                                >
                                                    {headerCell.label}
                                                    {filters.sortType === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                                    {filters.sortType === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                                </div>
                                            </TableCell>
                                        ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                page
                                    .posts
                                    .map((post, index) => {
                                        const isPostSelected = this.isPostSelected(post);
                                        const labelId = `post-list-checkbox-${index}`;
                                        const colorToUse = getStatusColorToUse(post);

                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => this.handlePostClick(post, isContributor)}
                                                role="checkbox"
                                                aria-checked={isPostSelected}
                                                tabIndex={-1}
                                                key={post.id}
                                                selected={isPostSelected}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isPostSelected}
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                        onClick={(ev) => this.onSelectPost(ev, post)}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                                    <span style={{ display: "flex" }}>
                                                        <span style={{ alignSelf: "center"}}>
                                                            {post.title}
                                                        </span>

                                                        <span style={{alignSelf: "center", marginLeft: "15px"}}>
                                                            {isContributor && post.alertUnopened && 
                                                                <HoverText label={<WarningIcon style={{color: "#e6911a"}}/>}>
                                                                    Post has been rejected or unpublished
                                                                </HoverText>
                                                            }
                                                        </span>
                                                    </span>
                                                </TableCell>
                                                <TableCell >
                                                    <MoreOptions
                                                        onPreview={this.onPreviewPost}
                                                        onClone={this.onClonePost}
                                                        onBoost={this.onBoostPost}
                                                        onUnpublish={this.onUnpublishPost}
                                                        onDelete={this.onDeletePosts}
                                                        onPublish={this.onPublishPost}
                                                        post={post}
                                                        onEditPost={async () => await this.onEditPost(isContributor, post.draftId, post.postType)}
                                                        isContributor={isContributor}
                                                        onInsight={this.onViewInsights}
                                                    />
                                                </TableCell>
                                                {
                                                    page.id === PostListingPageId.ALL &&
                                                    <TableCell align="center" style={{ textTransform: "capitalize", textAlign: "justify" }}>
                                                        <span className={`item-state ${colorToUse}`}>
                                                            {post.isSubmission ? "needs review" : post.status}
                                                        </span>
                                                    </TableCell>
                                                }
                                                {
                                                    page.id !== PostListingPageId.DRAFTS && page.id !== PostListingPageId.SUBMISSIONS &&
                                                    <TableCell align="center">{this.formatEpochTime(post.publishedTime)}</TableCell>
                                                }
                                                {
                                                    page.id === PostListingPageId.SUBMISSIONS &&
                                                    <TableCell align="center">{this.formatEpochTime(post.lastSubmittedTime)}</TableCell>
                                                }
                                                <TableCell align="center">{this.formatEpochTimeFromNow(post.updatedTime)}</TableCell>
                                                {
                                                    page.id !== PostListingPageId.SUBMISSIONS &&
                                                    <>
                                                        <TableCell align="center" className={this.isExpired(post.expiryTime) ? "expired" : ""}>
                                                            {this.formatEpochTime(post.expiryTime)}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ textTransform: "capitalize" }}>{post.postType}</TableCell>
                                                    </>
                                                }
                                                <TableCell align="center">{post.authorFullName ? post.authorFullName : post.author}</TableCell>
                                                <TableCell align="center"><ChipLabels chips={post.tags || []} emptyText="No tags selected" /></TableCell>
                                            </TableRow>
                                        );
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paging
                    currentPage={page.currentPage}
                    items={page.posts}
                    totalItems={page.totalPosts}
                    totalPages={page.totalPages}
                    onChangePage={this.onChangePage}
                    resetSelection={this.resetSelection}
                />
            </>
        );
    }

    private getToolbar = (isContributor: boolean): JSX.Element => {
        const { page } = this.props;
        const { selectedItems } = this.state;
        const selected = selectedItems.length === 1 && selectedItems[0];

        // delete button is disabled for contributors when trying to delete published posts
        const isDeleteBtnDisabled = isContributor &&
            (
                page.id === PostListingPageId.PUBLISHED ||
                page.id === PostListingPageId.SCHEDULED ||
                selectedItems.findIndex(item => item.status !== 'draft') !== -1
            );

        if (!selectedItems.length)
            return <React.Fragment></React.Fragment>;

        return (
            <TabContentToolbar>
                <div>
                    {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllPosts)}
                </div>
                <div>
                    {selectedItems.length === 1 &&
                        <React.Fragment>
                            {
                                this.getToolbarIcon("Edit", <EditIcon />, async () => await this.onEditPost(isContributor))
                            }
                            {this.getToolbarIconWithAsyncOnClick("Preview", <VisibilityIcon />, this.onPreviewPost)}
                            {
                                page.id === PostListingPageId.PUBLISHED && !isContributor &&
                                this.getToolbarIcon("Insights", <TimelineIcon />, this.onViewInsights)
                            }
                            {this.getToolbarIcon("Duplicate", <FileCopyIcon />, this.onClonePost)}
                            {
                                page.id === PostListingPageId.PUBLISHED && selectedItems[0].postType !== "compliance" && !isContributor &&
                                this.getToolbarIcon("Boost", <DoubleArrowIcon />, this.onBoostPost)
                            }
                            {
                                selected &&
                                (selected.status === PostListingPageId.PUBLISHED || selected.status === PostListingPageId.SCHEDULED) &&
                                !isContributor &&
                                this.getToolbarIcon("Unpublish", <CloudOffIcon />, this.onUnpublishPost)
                            }
                        </React.Fragment>
                    }
                    {
                        this.getToolbarIcon(
                            "Delete",
                            <DeleteIcon />,
                            () => this.onDeletePosts(),
                            isDeleteBtnDisabled,
                            isDeleteBtnDisabled ?
                                "Contact your administrator(s) to delete published or scheduled posts" :
                                undefined
                        )
                    }
                </div>
            </TabContentToolbar>
        );
    }

    private getToolbarIconWithAsyncOnClick = (title: string, icon: React.ReactNode, onClick: () => Promise<void>) => {
        return (
            <Button
                aria-label={title.toLowerCase()}
                color="primary"
                startIcon={icon}
                onClick={async () => { await onClick(); }}
            >
                {title}
            </Button>
        );
    }

    private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void, disabled?: boolean, toolTipText?: string) => {
        return (
            <LightTooltip title={toolTipText} arrow>
                <span>
                    <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick} disabled={disabled}>
                        {title}
                    </Button>
                </span>
            </LightTooltip>
        );
    }

    private isExpired = (time?: Epoch): boolean => {
        if (time) {
            return moment(new Date(time.date)) <= moment();
        }
        return false;
    }

    private isPostSelected = (post: PostListItem): boolean => {
        return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === post.id) !== -1;
    }

    private onChangeFilters = (filters: Partial<PostFilterValues>) => {
        const updatedFilters = { ...this.props.filters, ...filters };

        this.setState({ hasFiltersApplied: true, isPagedLoad: true });

        this.props.onUpdateFilters(
            updatedFilters,
            () => { this.props.fetchPage(1, updatedFilters); }
        );
    }

    private onClearFilters = () => {
        let filtersToSet = { ...this.props.defaultPostFilterValues };

        filtersToSet.tags = [];

        this.setState({ hasFiltersApplied: false, isPagedLoad: true });

        this.props.onUpdateFilters(
            filtersToSet,
            () => { this.props.fetchPage(1, {}); }
        );
    }

    private onClearAllPosts = () => {
        this.setState({ selectedItems: [] });
    }

    private onBoostPost = async (postToBoost?: PostListItem) => {
        let toBoost = postToBoost?.draftId ?? this.state.selectedItems[0].draftId;
        if (await confirm.show({ text: "This will revise the published date to today. Would you like to continue?", title: "Boost post" })) {
            this.props.publishPost(toBoost, Date.now().toString()).then(() => {
                this.onClearAllPosts();
            });
        }
    }

    private onClonePost = (postToClone?: PostListItem) => {
        let toClone = postToClone?.draftId ?? this.state.selectedItems[0].draftId;
        this.props.clonePost(toClone).then((response) => {
            if (!!response) {
                this.props.redirectTo("/" + this.props.tenant + "/admin/posts/edit/" + response);
            }
        });
    }

    /**
     * Removes content bands from redux when a pinned post
     * gets deleted. This will force a re-fetch if user goes to content bands
     * page during session and keeps their content up to date.
     */
    private getIsPinnedDeleted = (postToDelete?: PostListItem): boolean => {
        const { contentBands, publishedContentBands } = this.props.contentBandsState;
        const selectedIds = this.state.selectedItems.map((post: PostListItem) => post.id);
        const idsToCheck = postToDelete === undefined ? selectedIds : [postToDelete.id];

        return isPinnedDeleted(contentBands, publishedContentBands, idsToCheck);
    };

    private onDeletePosts = async (postToDelete?: PostListItem) => {
        const isPinnedDeleted = this.getIsPinnedDeleted(postToDelete);

        let singularPost = postToDelete ?? this.state.selectedItems[0];

        const confirmationMessage: string = (this.state.selectedItems.length > 1 && !postToDelete)
            ? `You're about to permanently delete these  ${this.state.selectedItems.length} posts.`
            : `You're about to permanently delete '${singularPost.title}'. You cannot undo this action.`;

        if (await confirm.show({
            title: `Delete Post`,
            text: (
                <div>
                    <div>{confirmationMessage}</div>
                    <br />
                    <div>Are you sure?</div>
                </div>
            ),
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
        })) {
            if (isPinnedDeleted)
                this.props.clearContentBands();

            if (postToDelete) {
                this.props.deletePosts([postToDelete]).then(() => {
                    this.onClearAllPosts();
                });
            }
            else {
                this.props.deletePosts(this.state.selectedItems).then(() => {
                    this.onClearAllPosts();
                });
            }
        }
    }

    private onEditPost = async (isContributor: boolean, postToEdit?: string, postType?: string) => {
        let toEdit = postToEdit ?? this.state.selectedItems[0].draftId;

        let postTypeToEdit = postType ?? this.state.selectedItems[0].postType;

        if (postTypeToEdit === POST_TYPES.COMPLIANCE) {
            const complianceStatus = await postsApi.GetPostUsersComplianceStatus(toEdit);

            if (complianceStatus.usersHaveComplied) {
                this.setState({ showConfirmComplianceEditPost: true, currDraftId: toEdit });
            } else {
                this.props.redirectTo("/" + this.props.tenant + "/admin/posts/edit/" + toEdit);
            }

            return;
        }

        // show confirmation for contributors if they edit any post that isn't a draft post
        if (isContributor &&
            (
                this.props.page.id === PostListingPageId.PUBLISHED ||
                this.props.page.id === PostListingPageId.SCHEDULED ||
                (this.state.selectedItems.length === 1 && this.state.selectedItems[0].status !== 'draft') || // editing via single item selection
                (this.state.showPostPreview && this.state.postToPreview.status !== 'draft') // editing via post preview
            )
        )
            this.setState({ showEditPublishedPostDialog: true, currDraftId: toEdit });
        else
            this.props.redirectTo("/" + this.props.tenant + "/admin/posts/edit/" + toEdit);
    }

    private onEditPostConfirm = () => {
        this.props.redirectTo("/" + this.props.tenant + "/admin/posts/edit/" + this.state.currDraftId);
        this.setState({ showEditPublishedPostDialog: false, showConfirmComplianceEditPost: false, currDraftId: "" });
    }

    private onHidePostPreview = () => {
        this.setState({ showPostPreview: false, postToPreview: { ...defaultPostOverview } });
    }

    /**
     * Determine the post endpoint we need to hit, drafts vs posts,
     * grab the PostOverview from API,
     * set postToPreview state value
     * and show preview dialog
     */
    private onPreviewPost = async (postToDisplay?: PostListItem) => {
        const { fetchDraftPost, clearFetchPost } = this.props;
        clearFetchPost();

        const { id, draftId, status } = postToDisplay ?? this.state.selectedItems[0];
        const isDraft = status === "draft";
        let task = isDraft ? fetchDraftPost(draftId, ImageScale.Modal) : postsApi.GetPostOverview(id, ImageScale.Modal);

        // since we use redux for fetching the draft the loading overlay is handled in postListing.tsx
        this.setState({ isLoadingPreview: !isDraft });
        try {
            const post = await task;
            const postToPreview = {
                ...post,
                author: isDraft
                    ? { name: post.author, email: post.authorEmail, avatar: { color: '#2196f3' } }
                    : post.author,
                imageUrl: isDraft
                    ? post.image.url
                    : post.imageUrl,
                draftId,
                // If there is no datePublished (OverviewModelV2), it must be a DraftPostModelv1 which has the prop publishTime
                datePublished: post.datePublished ?? post.publishTime,
            };

            this.setState({ postToPreview, showPostPreview: true });
        } catch (error) {
            this.setState({ showPostPreview: false });

        } finally {
            this.setState({ isLoadingPreview: false });
        }
    }

    private onViewInsights = (postToDisplay?: PostListItem) => {
        const queryParams = new URLSearchParams(window.location.search);

        const allPageNum = +(queryParams.get(QUERY_PARAM_KEYS.ALL_PAGE_NUMBER) ?? 1);
        const pubPageNum = +(queryParams.get(QUERY_PARAM_KEYS.PUBLISHED_PAGE_NUMBER) ?? 1);
        const schPageNum = +(queryParams.get(QUERY_PARAM_KEYS.SCHEDULED_PAGE_NUMBER) ?? 1);
        const dftPageNum = +(queryParams.get(QUERY_PARAM_KEYS.DRAFT_PAGE_NUMBER) ?? 1);

        let currentTab = +(queryParams.get(QUERY_PARAM_KEYS.TAB_NUMBER) ?? 0);
        if (currentTab > 3 || currentTab < 0) {
            currentTab = 0;
        }

        const pageContext = `${allPageNum},${pubPageNum},${schPageNum},${dftPageNum},${currentTab}`;

        this.props.redirectTo("/" + this.props.tenant + "/admin/posts/" + (postToDisplay?.id ?? this.state.selectedItems[0].id) + "?context=" + pageContext);
    }

    private onSelectAllPosts = () => {
        if (this.state.selectedItems.length === this.props.page.posts.length)
            this.setState({ selectedItems: [] });
        else
            this.setState({ selectedItems: this.props.page.posts });
    }

    private onSelectPost = (ev, post: PostListItem) => {
        ev.stopPropagation();
        let selectedItems = this.state.selectedItems.slice();

        if (selectedItems.findIndex((selectedItem) => selectedItem.id === post.id) === -1)
            selectedItems = selectedItems.concat([post]);
        else
            selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== post.id);

        this.setState({ selectedItems });
    }

    private handlePostClick = async (post: PostListItem, isContributor: boolean) => {
        if (post.status !== 'draft' && this.props.page.id !== 'submissions') {
            this.props.redirectTo("/" + this.props.tenant + "/admin/posts/" + (isContributor ? `edit/${post.draftId}` : post.id));
        }
        else if (!isContributor && this.props.page.id === 'submissions') {
            await this.onPreviewPost(post);
        }
        else {
            this.props.redirectTo("/" + this.props.tenant + "/admin/posts/edit/" + post.id);
        }
    }

    private onUnpublishPost = async (toUnpublish?: PostListItem) => {
        let toRemove = toUnpublish?.id ?? this.state.selectedItems[0].id;
        let postTypToEdit = toUnpublish?.postType ?? this.state.selectedItems[0].postType;

        if (postTypToEdit === POST_TYPES.COMPLIANCE) {
            const complianceStatus = await postsApi.GetPostUsersComplianceStatus(toRemove);

            if (complianceStatus.usersHaveComplied) {
                this.setState({ showConfirmUnpublishCompliancePost: true, currDraftId: toRemove });
            } else {
                this.unPublishPost(toRemove);
            }
        } else if (await confirm.show({ text: "Are you sure you would like to unpublish the selected post? Unpublishing removes the post and deletes all comments and reactions.", title: "Unpublish post" })) {
            this.unPublishPost(toRemove);
        }
    }

    private onUnpublishCompliancePostConfirm = () => {
        this.unPublishPost(this.state.currDraftId);
        this.setState({ showConfirmUnpublishCompliancePost: false, currDraftId: "" });
    }

    private onPublishPost = (toPublish: PostListItem) => {
        // only show compliance confirm if there is a currently published version of compliance post
        let shouldShowConfirmDialog = toPublish.postType === POST_TYPES.COMPLIANCE;

        if (shouldShowConfirmDialog) {
            this.setState({ currDraftId: toPublish.draftId, showConfirmPublishEditedCompliancePost: true });
            return;
        } else {
            this.onPublishPostConfirm(toPublish.draftId);
        }
    }

    private onPublishPostConfirm = (toPublishDraftId: string) => {
        let currentTime = moment(new Date()).toISOString();

        this.props.publishPost(toPublishDraftId, currentTime).then(() => {
            this.onClearAllPosts();
        });
    }

    private onChangePage = (page: number, rowsPerPage: number, filters?: Partial<PostFilterValues>) => {
        this.setState({ isPagedLoad: true });
        this.props.fetchPage(page + 1, filters ?? this.props.filters, rowsPerPage);
    }

    private resetSelection = () => {
        this.setState({ selectedItems: [] });
    }

    private getPublishedTimeTabLabel = (): string => {
        if (this.props.page.id === PostListingPageId.PUBLISHED || this.props.page.id === PostListingPageId.ALL) {
            return "Publish date";
        }

        return "Scheduled for"
    }

    private unPublishPost(toRemove: string) {
        this.props.unpublishPost(toRemove).then((succeeded) => {
            this.onClearAllPosts();
            if (succeeded) this.setState({successMessage: "Successfully unpublished! Your post has been moved to drafts."})
        });
    }
}


interface ComponentProps {
    show: boolean;
    page: PostListingPage;
    fetchPage: (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => void;
    availableTopics: TenantSettingsTagGroup[];
    filters: Partial<PostFilterValues>;
    onUpdateFilters: (newFilters: Partial<PostFilterValues>, callback?: (() => void | undefined)) => void;
    defaultPostFilterValues: PostFilterValues;
}

interface ComponentState {
    hasFiltersApplied: boolean;
    isPagedLoad: boolean;
    selectedItems: PostListItem[];
    showPostPreview: boolean;
    postToPreview: PostOverview;
    isLoadingPreview: boolean;
    showEditPublishedPostDialog: boolean;
    showConfirmComplianceEditPost: boolean;
    showConfirmUnpublishCompliancePost: boolean;
    showConfirmPublishEditedCompliancePost: boolean;
    currDraftId: string;
    successMessage: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
        tenantSettings: state.settings.tenantSettings,
        defaultLang: state.settings.tenantSettings ? state.settings.tenantSettings.defaultLCID : "en-us",
        lcidMappings: state.resources.lcidMappings,
        publishing: state.posts.publishing,
        currentUser: state.settings.currentUser,
        submitting: state.posts.submitting,
        contentBandsState: state.contentBands
    }),
    {
        clonePost: actions.clonePost,
        deletePosts: actions.deleteDraftPosts,
        publishPost: actions.publishPost,
        unpublishPost: actions.unpublishPost,
        redirectTo: push,
        fetchDraftPost: actions.fetchDraftPost,
        clearFetchPost: actions.clearFetchPost,
        submitPost: actions.submitPost,
        clearContentBands: contentBandsSlice.actions.CLEAR_BANDS
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PostList);
