import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import InfoHover from "modules/common/components/hovers/infoHover";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import {
    NewsletterDetails,
    NewsletterFutureIssueDetailModel,
    NewsletterTemplateImageType,
    SaveNewsletterIssueModelStateErrors,
    UploadIssueImageModel,
    HEADER,
    FOOTER
} from "../../models";

import { HeaderFooter } from "./headerFooter";
import IssueDetails from "./issueDetails";
import PinnedContent from "./pinnedContent";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";

import moment from "moment";
import _ from "lodash";

import "modules/common/components/authoring/authoring.sass";
import "../../styles/dialogs.sass";
import { useEffect, useState } from "react";
import NewsletterLanguageBar from "modules/newsletters/edit/components/newsletterLanguageBar";
import useLanguages from "modules/common/hooks/data/useLanguages";
import { TranslatedNewsletterContent } from "modules/common/components/authoring/models";
import { deepCopy } from "utils/deepCopy";


const EditQueuedIssue: React.FunctionComponent<PropsWithRedux> = props => {
    const onChangeIssue = (changed: Partial<NewsletterFutureIssueDetailModel>) => {
        let newIssue = {...issue!, ...changed}
        setIssue(newIssue);
    }

    const onResetIssue = () => {
        setIsSaving(true);

        props.onResetIssue().then(() => {
            setIsSaving(false);
            props.onCloseIssue();
        });
    }

    const onSaveHeaderFooterImage = (issue: NewsletterFutureIssueDetailModel): Promise<void> => {
        return new Promise((resolve) => {
            if (hasFooterImageChanged || hasHeaderImageChanged) {
                if (hasHeaderImageChanged) {
                    if (!headerImage && !!issueFromServer && issueFromServer.emailTemplateHeaderImageIsCustomized)
                        return props.deleteIssueImage(props.newsletter.id, issue.issueDateLocal, HEADER).then(() => resolve());
                }

                if (hasFooterImageChanged) {
                    if (!footerImage && !!issueFromServer && issueFromServer.emailTemplateFooterImageIsCustomized)
                        return props.deleteIssueImage(props.newsletter!.id, issue.issueDateLocal, FOOTER).then(() => resolve());
                }

                const uploadImageModel: UploadIssueImageModel = {
                    newsletterId: props.newsletter!.id,
                    headerImage: headerImageBase64,
                    footerImage: footerImageBase64,
                    issueDate: issue.issueDateLocal
                };

                return props.uploadIssueHeaderFooterImage(uploadImageModel).then(() => resolve());
            } else {
                resolve();
            }
        });
    }

    const onSaveIssue = (): Promise<void> => {
        setIsSaving(true);

        return props.saveIssue(issue!)
            .then((result) => {
                if (!!result.success) {
                    return onSaveHeaderFooterImage(result.success);
                } else if (!!result.error) {
                    const firstError = _.first(_.values(result.error.ModelState));
                    !!firstError && props.onError(firstError[0]);
                    setIsSaving(false);
                    setIssueModelErrors(result.error.ModelState);
                }
            }).then(() => {
                setIsSaving(false);
                setIssueModelErrors(null);
                props.onSuccess('Issue saved');
                props.onIssueChanged();
            });
    }

    const onChangeImage = (imageType: NewsletterTemplateImageType, image?: File) => {
        let reader = new FileReader();
        if (imageType === HEADER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                setHeaderImage(image);
                setHasHeaderImageChanged(true);
                setHeaderImageBase64(reader.result?.toString());
            };
        }
        else if (imageType === FOOTER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                setFooterImage(image);
                setHasFooterImageChanged(true);
                setFooterImageBase64(reader.result?.toString());
            };
        }
    }

    const updateTranslatedContent = (toSet: TranslatedNewsletterContent) => {
        let updatedIssue = deepCopy(issue);
        updatedIssue!.translatedContent = toSet;
        setIssue(updatedIssue);
    }

    const allLanguagesHaveRequiredContent = () => {
        let validContent = true;

        selectedLanguages?.forEach(l => {
            if(!issue!.translatedContent[l.lcid].subject || issue!.translatedContent[l.lcid].subject === "") {
                validContent = false;
            }
        });

        return validContent;
    }

    const [issue, setIssue] = useState<NewsletterFutureIssueDetailModel | undefined>();
    const [issueFromServer, setIssueFromServer] = useState<NewsletterFutureIssueDetailModel | undefined>();
    const [isLoadingIssue, setIsLoadingIssue] = useState<boolean | undefined>();
    const [issueModelErrors, setIssueModelErrors] = useState<SaveNewsletterIssueModelStateErrors | null | undefined>();

    const [headerImage, setHeaderImage] = useState<File | null | undefined>();
    const [headerImageBase64, setHeaderImageBase64] = useState<string | undefined>();
    const [isLoadingHeaderImage, setIsLoadingHeaderImage] = useState<boolean | undefined>();
    const [hasHeaderImageChanged, setHasHeaderImageChanged] = useState<boolean | undefined>();

    const [footerImage, setFooterImage] = useState<File | null | undefined>();
    const [footerImageBase64, setFooterImageBase64] = useState<string | undefined>();
    const [isLoadingFooterImage, setIsLoadingFooterImage] = useState<boolean | undefined>();
    const [hasFooterImageChanged, setHasFooterImageChanged] = useState<boolean | undefined>();

    const [isSaving, setIsSaving] = useState<boolean | undefined>();

    const {
        availableLanguages,
        selectedLanguages,
        activeLcid,
        defaultLcid,
        lcidMappings,
        onRemoveLanguage,
        onSelectLanguage,
    } = useLanguages(issue?.translatedContent);

    useEffect(() => {
        moment.locale("en");

        const loadHeaderAndFooterImage = (issueDetails: NewsletterFutureIssueDetailModel) => {
            if (issueDetails.emailTemplateHeaderImageIsCustomized) {
                setIsLoadingHeaderImage(true);
                props.getIssueImage(props.newsletter.id, issueDetails.issueDateLocal, HEADER).then((image) => {
                    setHeaderImage(image);
                    setIsLoadingHeaderImage(false);
                });
            } else if (props.newsletter.emailTemplateHeaderImageIsCustomized) {
                setIsLoadingHeaderImage(true);
                props.getNewsletterImage(props.newsletter.id, HEADER).then((image) => {
                    setHeaderImage(image);
                    setIsLoadingHeaderImage(false);
                });
            } else {
                setHeaderImage(null);
                setIsLoadingHeaderImage(false);
            }
    
            if (issueDetails.emailTemplateFooterImageIsCustomized) {
                setIsLoadingFooterImage(true);
                props.getIssueImage(props.newsletter.id, issueDetails.issueDateLocal, FOOTER).then((image) => {
                    setFooterImage(image);
                    setIsLoadingFooterImage(false);
                });
            }
            else if (props.newsletter.emailTemplateFooterImageIsCustomized) {
                setIsLoadingFooterImage(true);
                props.getNewsletterImage(props.newsletter.id, FOOTER).then((image) => {
                    setFooterImage(image);
                    setIsLoadingFooterImage(false);
                });
            }
            else {
                setFooterImage(null);
                setIsLoadingFooterImage(false);
            }
        }

        const onEditFutureIssue = (issueDateLocal: string) => {
            setIsLoadingIssue(true);
    
            props.getFutureIssueDetails(props.newsletter.id, issueDateLocal)
                .then((issueDetails) => {

                    //Check and fix if legacy.
                    if(Object.keys(issueDetails.translatedContent).length === 0) {
                        issueDetails.translatedContent = {[defaultLcid]: {
                            subject: issueDetails.emailSubject,
                            preheader: issueDetails.emailPreheader,
                            header: issueDetails.emailTemplateHeaderText, 
                            body: issueDetails.bodyText,
                            footer: issueDetails.emailTemplateFooterText
                        }};
                    }

                    setIssue(deepCopy(issueDetails));
                    setIssueFromServer(deepCopy(issueDetails));
                    setIsLoadingIssue(false);
                    loadHeaderAndFooterImage(issueDetails);
            });
        }

        const onAddFutureIssue = () => {
            const { newsletter } = props;
    
            const futureDate: string = moment().add(1, "day").set("hours", 12).set("minutes", 0).toISOString();
    
            const isMissingTranslatedContent = !newsletter.translatedContent || Object.keys(newsletter.translatedContent).length === 0
            const makeupTranslatedContent:TranslatedNewsletterContent = {
                [defaultLcid]: {
                    subject: newsletter.emailSubject,
                    preheader: newsletter.emailPreheader,
                    header: newsletter.emailTemplateHeaderText,
                    body: newsletter.bodyText,
                    footer: newsletter.emailTemplateFooterText,
                }
            };

            const newFutureIssue: NewsletterFutureIssueDetailModel = {
                issueDateLocal: "",
                issueType: "Adhoc",
                newsletterId: newsletter.id,
                tenantId: newsletter.tenantId,
                emailPreheader: newsletter.emailPreheader,
                emailSubject: newsletter.emailSubject,
                emailTemplateFooterText: newsletter.emailTemplateFooterText,
                emailTemplateHeaderText: newsletter.emailTemplateHeaderText,
                bodyText: newsletter.bodyText,
                featuredContent: [],
                overriddenIssueDateTime: futureDate,
                status: "Future",
                emailTemplateFooterImageIsCustomized: false,
                emailTemplateHeaderImageIsCustomized: false,
                isCustomized: false,
                sendDateTime: "",
                translatedContent: isMissingTranslatedContent ? makeupTranslatedContent : newsletter.translatedContent
            };
    
            setIssue(newFutureIssue);
            setIssueFromServer(newFutureIssue);
            setIsLoadingIssue(false);
    
            loadHeaderAndFooterImage(newFutureIssue);
        }

        if (props.isAddingNewIssue) {
            onAddFutureIssue();
        } else if (!!props.editingIssueDateLocal) {
            onEditFutureIssue(props.editingIssueDateLocal!);
        }
    }, [])

    const { isAddingNewIssue } = props;

    return (
        <React.Fragment>
            <Dialog 
                disableEnforceFocus 
                open={!isLoadingIssue && !!issue} 
                maxWidth={false} 
                scroll="paper" 
                onClose={props.onCloseIssue} 
                classes={{ paper: "newsletter-dialog newsletter-issue-dialog" }}
            >
                <DialogTitle className="newsletter-dialog-title">
                    <div>
                        {isAddingNewIssue && <span>New issue</span>}
                        {!isAddingNewIssue && <span>Edit issue</span>}
                        <InfoHover>Changing settings for a specific issue will not affect succeeding issues. Edit the newsletter to change the settings of this and all succeeding issues.</InfoHover>
                    </div>
                    <IconButton onClick={props.onCloseIssue} size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PickerLocalization>
                        <div className="authoring-page">
                            {!isLoadingIssue && !!issue &&
                                <React.Fragment>
                                    <IssueDetails 
                                        issue={issue} 
                                        issueFromServer={issueFromServer} 
                                        issueModelErrors={issueModelErrors} 
                                        newsletterTitle={props.newsletter.title} 
                                        onChange={onChangeIssue} 
                                        activeLcid={activeLcid}
                                        languageBar={
                                            <NewsletterLanguageBar 
                                                newsletterContent={issue.translatedContent}
                                                availableLanguages={availableLanguages}
                                                selectedLanguages={selectedLanguages}
                                                defaultLcid={defaultLcid}
                                                lcidMappings={lcidMappings}
                                                activeLcid={activeLcid}
                                                onChangeTranslatedContent={updateTranslatedContent}
                                                onRemoveLanguage={onRemoveLanguage}
                                                onSelectLanguage={onSelectLanguage}
                                                getNewsletterTranslation={props.translateNewsletter}
                                                newsletterId={props.newsletter.id}
                                            />
                                        }
                                    />
                                    <HeaderFooter
                                        issue={issue}
                                        newsletter={props.newsletter}
                                        footerImage={footerImage || null}
                                        headerImage={headerImage || null}
                                        isLoadingFooterImage={isLoadingFooterImage}
                                        isLoadingHeaderImage={isLoadingHeaderImage}
                                        onChange={onChangeIssue}
                                        onSetImage={onChangeImage}
                                        onError={props.onError} 
                                        activeLcid={activeLcid}
                                        />
                                    <Divider />
                                    <PinnedContent issue={issue} onChange={onChangeIssue} />
                                </React.Fragment>
                            }
                        </div>
                    </PickerLocalization>
                </DialogContent>
                {!!issue && <DialogActions className="newsletter-dialog-actions">
                    <div>
                        {!isAddingNewIssue && props.newsletter.recurrenceType !== "Adhoc" &&
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<SyncIcon />}
                                disabled={!issue.isCustomized}
                                onClick={onResetIssue}
                            >
                                Reset issue to original settings
                            </Button>
                        }
                    </div>
                    <div>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={props.onCloseIssue}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                !allLanguagesHaveRequiredContent() 
                                || (isAddingNewIssue && !issue.overriddenIssueDateTime) 
                                || (!isAddingNewIssue && !(!!hasHeaderImageChanged || !!hasFooterImageChanged || !_.isEqual(issue, issueFromServer)))
                            }
                            onClick={onSaveIssue}
                        >
                            Save
                        </Button>
                    </div>
                </DialogActions>}
                <LoadingOverlay show={!!isSaving} styles={{ paddingTop: 150 }} />
            </Dialog>
            <LoadingOverlay show={!!isLoadingIssue} styles={{ paddingTop: 150 }} />
        </React.Fragment>
    );
}

interface ComponentProps {
    newsletter: NewsletterDetails;
    isAddingNewIssue: boolean;
    editingIssueDateLocal: string | null;
    onCloseIssue: () => any;
    onIssueChanged: () => any;
    onResetIssue: () => Promise<boolean>;
    onError: (message: string) => any;
    onSuccess: (message: string) => any;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ownProps,
    {
        getFutureIssueDetails: actions.getFutureIssueDetails,
        getNewsletterImage: actions.getNewsletterImage,
        getIssueImage: actions.getIssueImage,
        deleteIssueImage: actions.deleteIssueImage,
        uploadIssueImage: actions.uploadIssueImage,
        uploadIssueHeaderFooterImage: actions.uploadIssueHeaderFooterImage,
        translateNewsletter: actions.translateNewsletter,
        saveIssue: actions.saveIssue
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditQueuedIssue);

