import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { ILanguage } from "modules/localization/models";
import { TranslatedContent, TranslatedNewsletterContent } from "../../components/authoring/models";
import { lcidMappings } from "modules/resources";
import { IHeaderTranslation } from "modules/contentBands/models";
import { TranslatablePostContent } from "modules/posts/models";

/**
 * Custom hook to encapsulate some language logic.
 *
 * returns {
 *  - availableLanguages - tenant's languages available for selection
 *  - selectedLanguages - current selected languages
 *  - activeLcid - current language being viewed
 *  - defaultLcid - tenant's default lcid
 *  - lcidMappings - lcidMappingsResource
 *  - onRemoveLanguage - function hanlder to remove a selected language
 *  - onSelectLanguage - function handler to add a language and set it as active
 * }
 */
export type useLanguagesData = {
    availableLanguages: ILanguage[] | undefined;
    selectedLanguages: ILanguage[] | undefined;
    activeLcid: string;
    defaultLcid: string;
    lcidMappings: lcidMappings;
    onRemoveLanguage: (lcid: string) => void;
    onSelectLanguage: (lcid: string) => void;
};

const useLanguages = (translations?: Record<string, TranslatablePostContent | IHeaderTranslation | TranslatedNewsletterContent> | undefined): useLanguagesData => {
    const tenantSettings = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings);
    const lcidMappings: lcidMappings = useSelector((state: GlobalApplicationState) => state.resources.lcidMappings);

    const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
    const [selectedLanguages, setSelectedLanguages] = useState<ILanguage[]>();
    const [activeLcid, setActiveLcid] = useState<string>(tenantSettings.defaultLCID || "en-us");

    /**
     * Grab available languages data
     */
    const loadAvailableLanguages = React.useCallback(() => {
        if (!lcidMappings || !tenantSettings) return [];

        const defaultLcid: string = tenantSettings.defaultLCID;
        const lcids: string[] = Object.keys(tenantSettings.translatedContent);
        let languages: ILanguage[] = [];

        // handle default lcid
        languages.push({
            availableForTranslation: false,
            language: lcidMappings[defaultLcid]["languageInEnglish"] || lcidMappings[defaultLcid].language.split(" ")[0],
            lcid: defaultLcid,
            translateAs: defaultLcid,
            visible: true,
        });

        // handle rest of lcids
        lcids.forEach((lcid) => {
            if (lcid !== defaultLcid) {
                const currentMapping = lcidMappings[lcid];

                if (currentMapping) {
                    languages.push({
                        availableForTranslation: currentMapping["availableForTranslation"],
                        language: currentMapping["languageInEnglish"] || currentMapping.language.split(" ")[0],
                        lcid: lcid,
                        translateAs: currentMapping["translateAs"],
                        visible: !!translations && !!translations[lcid],
                    });
                }
            }
        });

        setAvailableLanguages(languages);
    }, [lcidMappings, tenantSettings, translations]);

    /**
     * Load selected languages on changes to available languages and translations prop
     */
    useEffect(() => {
        if (availableLanguages && translations) {
            let newSelected: ILanguage[] = [];
            // grab each language in translations prop
            Object.keys(translations).forEach((key: string) => {
                // find in available languages
                let available = availableLanguages.find((lang: ILanguage) => lang.lcid === key);
                if (available) newSelected.push(available);

                setSelectedLanguages(newSelected);
            });
        }
    }, [availableLanguages, translations, loadAvailableLanguages]);

    /**
     * Load available languages on changes to tenant settings or lcid mappings
     */
    useEffect(() => {
        loadAvailableLanguages();
    }, [tenantSettings, lcidMappings, loadAvailableLanguages]);

    const onAddLanguage = (lcid: string): void => {
        let selected = selectedLanguages?.find((lang: ILanguage) => lang.lcid === lcid);
        let available = availableLanguages?.find((lang: ILanguage) => lang.lcid === lcid);

        if (!selected && available) {
            let newSelected = selectedLanguages ? [...selectedLanguages] : [];
            newSelected.push(available);

            setSelectedLanguages(newSelected);
        }
    };

    /**
     * Remove language from selected list
     * - also sets active lcid to tenant default if language removed was the active one
     */
    const onRemoveLanguage = (lcid: string): void => {
        let selected = selectedLanguages?.find((lang: ILanguage) => lang.lcid === lcid);
        if (selected && selectedLanguages) {
            let newSelected = [...selectedLanguages];
            newSelected = newSelected.filter((lang: ILanguage) => lang.lcid !== lcid);

            setSelectedLanguages(newSelected);

            // set active language to default if language to remove was active one
            if (lcid === activeLcid) setActiveLcid(tenantSettings.defaultLCID);
        }
    };

    const onSelectLanguage = (lcid: string): void => {
        onAddLanguage(lcid);
        setActiveLcid(lcid);
    };

    return {
        availableLanguages,
        selectedLanguages,
        activeLcid,
        defaultLcid: tenantSettings.defaultLCID,
        lcidMappings,
        onRemoveLanguage,
        onSelectLanguage,
    };
};

export default useLanguages;
